.cls-1 {
  fill: #1170cc;
}

.cls-1, .cls-3, .cls-4, .cls-5, .cls-6 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #b4cae2;
}

.cls-3 {
  fill: #010300;
}

.cls-4 {
  fill: #000100;
}

.cls-5 {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
}

.cls-6 {
  fill: #070707;
}

/* .image-hover-highlight {
    -webkit-transition: all 0.50s;
    transition: all 0.50s;
    &:hover {
        border: 1px solid gray;
        filter: brightness(130%);
        -webkit-filter: brightness(130%);
        -moz-filter: brightness(130%);
        -o-filter: brightness(130%);
        -ms-filter: brightness(130%);
        -webkit-transition: all 0.50s;
        transition: all 0.50s;
    }
} */

.country:hover { 
  filter: brightness(140%); 
  transition: all 0.40s;
}