.video-background {
    position: relative;
    overflow: hidden;
    width: 600px;
    height: 400px;
  }
  
  .video-background iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 400px;
    transform: translate(-50%, -50%);
  }
  
  @media  (max-width: 400px) {
  
      .video-background {
          width: 300px;
          height: 200px;
      }
  
      .video-background iframe {
          width: 300px;
          height: 200px;
      }
  
  }
  
  @media  (min-width: 401px) and (max-width: 769px) {
  
      .video-background {
          width: 380px;
          height: 254px;
      }
  
      .video-background iframe {
          width: 380px;
          height: 254px;
      }
  
  }
  
  