@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&display=swap');

@import url('https://unpkg.com/@themesberg/flowbite@1.2.0/dist/flowbite.min.css');

.heading{
  font-family: 'Merriweather', serif;
  /* font-weight:600;  */
  font-style:italic;
}

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  img {
    pointer-events: none;
  }

  #text3d {

    letter-spacing: .02em;
    text-shadow: 
     
      1px 7px 40px rgba(197, 188, 181, 0.329),
      2px 3px 5px rgba(196, 185, 176, 0.499),
      3px 7px 8px rgba(170, 155, 144, 0.533),
      4px 8px 30px rgba(133, 102, 78, 0.669);
 }