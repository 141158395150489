@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&family=Quicksand&family=Roboto:wght@300;400&family=Sawarabi+Mincho&family=Syne+Mono&display=swap');

#intrapreneurial{
  font-family: 'Quicksand', sans-serif;
}

.head{
  background-color: #c0eeda;
}

.box:hover{
  box-shadow: 0 0 0 300px rgba(0, 0, 0, 0.8) inset;
  cursor: pointer;
}

.box:hover .d{
  opacity: 1;
  transform: scale(1);
}

